<template>
  <v-bottom-navigation fixed background-color="#fff" class="layout-mobile-navigation" color="#002fff">
    <v-btn v-for="route in firstRoutes" :key="route.id" :to="route.to" class="layout-mobile-navigation__item">
      <span>{{ route.text }}</span>

      <div class="layout-mobile-navigation__icon">
        <v-icon v-if="isString(route.icon)">{{ route.icon }}</v-icon>
        <component :is="route.icon" v-else />

        <div v-if="route.badge" class="layout-mobile-navigation__icon-chat-unread rounded-circle" />
      </div>
    </v-btn>

    <v-menu v-if="isTrim" content-class="elevation-0 layout-mobile-navigation__menu" offset-y top min-width="100vw">
      <template #activator="{on, attrs}">
        <v-btn :class="{ 'layout-mobile-navigation__active-button': hasActiveItem }" v-bind="attrs" v-on="on">
          <span>{{ $t('navigation.other') }}</span>
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list class="layout-mobile-navigation__menu-list">
        <v-list-item
          v-for="route in otherRoutes"
          :key="route.id"
          :class="{ 'layout-mobile-navigation__menu-item--active': checkActive(route.to.name) }"
          :to="route.to"
          color="#002fff"
          class="layout-mobile-navigation__menu-item px-3"
        >
          <v-list-item-icon class="mr-3 my-0 align-self-center">
            <v-icon v-if="isString(route.icon)">{{ route.icon }}</v-icon>
            <component :is="route.icon" v-else class="layout-mobile-navigation__custom-icon" />
          </v-list-item-icon>

          <v-list-item-title class="font-weight-medium">
            {{ route.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-bottom-navigation>
</template>

<script>
// node_modules
import { mapState } from 'vuex';

// Constants
import {
  PROJECTS,
  PROJECTS_CREATE,
  PROJECTS_EDIT,
  PROJECTS_DETAILED,
  PROJECTS_ARCHIVE,
  CLIENTS,
  CLIENTS_CREATE,
  CLIENTS_EDIT,
  CLIENTS_ARCHIVE,
  CLIENTS_DETAILED,
  CONTRACTORS_DETAILED,
  CONTRACTORS,
  ANNOUNCEMENTS_SENT,
  ANNOUNCEMENTS_SCHEDULED,
  ANNOUNCEMENTS_DRAFTS,
} from '@/constants/routes';
import * as userRoles from '@/constants/roles';

export default {
  name: 'LayoutMobileNavigation',

  props: {
    routes: { type: Array, required: true },
  },

  computed: {
    ...mapState({
      userRole: state => state.user.role,
    }),

    firstRoutes() {
      return this.routes.slice(0, this.displayedMenuItems);
    },

    isClient() {
      return this.userRole === userRoles.CLIENT;
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },

    displayedMenuItems() {
      if (this.isClient || this.isMobile) {
        return 4;
      }

      return 7;
    },

    otherRoutes() {
      return this.routes.slice(this.displayedMenuItems);
    },

    isTrim() {
      return this.routes.length > this.displayedMenuItems;
    },

    hasActiveItem() {
      return [
        PROJECTS,
        PROJECTS_CREATE,
        PROJECTS_EDIT,
        PROJECTS_DETAILED,
        PROJECTS_ARCHIVE,
        CLIENTS,
        CLIENTS_CREATE,
        CLIENTS_EDIT,
        CLIENTS_ARCHIVE,
        CLIENTS_DETAILED,
        CONTRACTORS_DETAILED,
        CONTRACTORS,
        ANNOUNCEMENTS_SENT,
        ANNOUNCEMENTS_SCHEDULED,
        ANNOUNCEMENTS_DRAFTS,
      ].includes(this.$route.name);
    },
  },

  methods: {
    checkActive(routeName) {
      return !!this.$route.matched.find(match => match.name === routeName);
    },

    isString(icon) {
      return typeof icon === 'string';
    },
  },
};
</script>

<style lang="scss">
.v-item-group.v-bottom-navigation .v-btn.v-btn--active:before,
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}

.layout-mobile-navigation__menu-list .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.layout-mobile-navigation.theme--light.v-bottom-navigation
  .v-btn:not(.v-btn--active):not(.layout-mobile-navigation__active-button) {
  color: $--bottom-navigation-default-color !important;
}

.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.layout-mobile-navigation__active-button {
  color: $--bottom-navigation-active-color !important;
}

.layout-mobile-navigation {
  .v-btn {
    min-width: 0 !important;
  }

  &__menu {
    left: 0;
    border-bottom: 1px solid rgba($--bottom-navigation-default-color, 0.5);
    transform: translateY(0);

    &.elevation-0 {
      box-shadow: none !important;
    }

    & > * {
      padding: 0 !important;
    }
  }

  &__menu-list {
    border-top: 0.5px solid rgba(149, 154, 174, 0.4) !important;
  }

  &__icon {
    position: relative;
  }

  &__icon-chat-unread {
    position: absolute;
    bottom: 3px;
    right: -2px;
    background-color: #2e50e5;
    width: 9px;
    height: 9px;
  }
}
</style>
