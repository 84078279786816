<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6668 0.000278623C18.5077 0.000323413 20 1.4927 20 3.33362V16.6668C20 18.5077 18.5076 20.0001 16.6667 20.0001H3.33334C1.49239 20.0001 -2.51689e-06 18.5077 0 16.6668L1.82291e-05 3.33329C2.0746e-05 1.49231 1.49246 -9.05664e-05 3.33344 -4.57754e-05L16.6668 0.000278623ZM9.68595 10.7751C9.42556 11.0355 9.00338 11.0355 8.74304 10.775L7.25048 9.28187C6.81643 8.84765 6.11251 8.8477 5.67853 9.28199C5.24483 9.71599 5.24495 10.4194 5.67881 10.8532L8.03594 13.2104C8.68681 13.8612 9.74209 13.8612 10.393 13.2104L14.7145 8.88885C15.1483 8.455 15.1483 7.75159 14.7145 7.31773C14.2806 6.88388 13.5772 6.88388 13.1434 7.31773L9.68595 10.7751Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTasks',
};
</script>
