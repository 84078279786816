<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9999 0C17.1045 0 17.9999 0.89543 17.9999 2V4.54987C17.9999 4.68247 17.9473 4.80963 17.8535 4.9034L9.14708 13.6108C9.05349 13.7044 9.00084 13.8313 9.00065 13.9636L8.99565 17.495C8.99526 17.7711 9.2188 17.9953 9.49494 17.9957L13.0334 18.0007C13.1663 18.0008 13.2937 17.9482 13.3877 17.8542L17.1464 14.0955C17.4614 13.7805 17.9999 14.0036 17.9999 14.4491V18C17.9999 19.1046 17.1045 20 15.9999 20L2.00009 20C0.89553 20 0.000101289 19.1045 9.54331e-05 18L1.06038e-05 2.00001C4.74748e-06 0.895439 0.895437 0 2.00001 0H15.9999ZM18.4244 7.16151C18.6197 6.96625 18.9362 6.96625 19.1315 7.16151L19.8384 7.8684C20.0337 8.06367 20.0337 8.38025 19.8384 8.57551L12.5607 15.8532C12.4667 15.9472 12.3393 15.9999 12.2064 15.9997L11.4979 15.9987C11.2218 15.9983 10.9983 15.7741 10.9987 15.498L10.9996 14.7926C10.9998 14.6603 11.0525 14.5334 11.1461 14.4398L18.4244 7.16151ZM8.99994 10.5C8.99994 10.2238 8.77608 9.99996 8.49994 9.99996H4.49994C4.2238 9.99996 3.99994 10.2238 3.99994 10.5V11.5C3.99994 11.7761 4.2238 12 4.49994 12H8.49994C8.77608 12 8.99994 11.7761 8.99994 11.5V10.5ZM11.9999 6.49996C11.9999 6.22381 11.7761 5.99996 11.4999 5.99996H4.49994C4.2238 5.99996 3.99994 6.22381 3.99994 6.49996V7.49996C3.99994 7.7761 4.2238 7.99996 4.49994 7.99996H11.4999C11.7761 7.99996 11.9999 7.7761 11.9999 7.49996V6.49996Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconContracts',
};
</script>
