<template>
  <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 16.6C20 16.8209 20.1791 17 20.4 17H20.5C21.3284 17 22 17.6716 22 18.5C22 19.3284 21.3284 20 20.5 20H1.5C0.671573 20 0 19.3284 0 18.5C0 17.6716 0.671573 17 1.5 17H1.6C1.82091 17 2 16.8209 2 16.6V2.00005C2 0.895484 2.89543 5.29454e-05 4 5.17435e-05L18 3.65087e-05C19.1046 3.53067e-05 20 0.895466 20 2.00004V16.6ZM7.5 9C7.22386 9 7 9.22386 7 9.5V10.5C7 10.7761 7.22386 11 7.5 11H9.5C9.77614 11 10 10.7761 10 10.5V9.5C10 9.22386 9.77614 9 9.5 9H7.5ZM7.5 5C7.22386 5 7 5.22386 7 5.5V6.5C7 6.77614 7.22386 7 7.5 7H9.5C9.77614 7 10 6.77614 10 6.5V5.5C10 5.22386 9.77614 5 9.5 5H7.5ZM7.5 13C7.22386 13 7 13.2239 7 13.5V14.5C7 14.7761 7.22386 15 7.5 15H9.5C9.77614 15 10 14.7761 10 14.5V13.5C10 13.2239 9.77614 13 9.5 13H7.5ZM12.5 13C12.2239 13 12 13.2239 12 13.5V14.5C12 14.7761 12.2239 15 12.5 15H14.5C14.7761 15 15 14.7761 15 14.5V13.5C15 13.2239 14.7761 13 14.5 13H12.5ZM12.5 9C12.2239 9 12 9.22386 12 9.5V10.5C12 10.7761 12.2239 11 12.5 11H14.5C14.7761 11 15 10.7761 15 10.5V9.5C15 9.22386 14.7761 9 14.5 9H12.5ZM12.5 5C12.2239 5 12 5.22386 12 5.5V6.5C12 6.77614 12.2239 7 12.5 7H14.5C14.7761 7 15 6.77614 15 6.5V5.5C15 5.22386 14.7761 5 14.5 5H12.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconProjects',
};
</script>
