<template>
  <svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.151 5.951L11.838 0.638C11.431 0.231 10.87 0 10.287 0H2.40001C1.19001 0 0.211012 0.99 0.211012 2.2L0.200012 19.8C0.200012 21.01 1.17901 22 2.38901 22H15.6C16.81 22 17.8 21.01 17.8 19.8V7.513C17.8 6.93 17.569 6.369 17.151 5.951ZM11.2 12.1C11.805 12.1 12.3 12.595 12.3 13.2V16.5C12.3 17.105 11.805 17.6 11.2 17.6H10.1C10.1 18.205 9.60501 18.7 9.00001 18.7C8.39501 18.7 7.90001 18.205 7.90001 17.6H6.80001C6.19501 17.6 5.70001 17.105 5.70001 16.5C5.70001 15.895 6.19501 15.4 6.80001 15.4H10.1V14.3H6.80001C6.19501 14.3 5.70001 13.805 5.70001 13.2V9.9C5.70001 9.295 6.19501 8.8 6.80001 8.8H7.90001C7.90001 8.195 8.39501 7.7 9.00001 7.7C9.60501 7.7 10.1 8.195 10.1 8.8H11.2C11.805 8.8 12.3 9.295 12.3 9.9C12.3 10.505 11.805 11 11.2 11H7.90001V12.1H11.2ZM11.2 6.6C10.595 6.6 10.1 6.105 10.1 5.5V1.65L15.05 6.6H11.2Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconInvoices',
};
</script>
