<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 21C0.447715 21 -0.00645391 20.5505 0.0623925 20.0026C0.282756 18.2486 1.0799 16.6064 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C14.9201 16.6064 15.7172 18.2486 15.9376 20.0026C16.0065 20.5505 15.5523 21 15 21H1ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM15.8029 14.7451C15.6783 14.5898 15.8209 14.3636 16.0086 14.4303C17.2761 14.8808 18.4036 15.6656 19.2683 16.7068C20.0486 17.6463 20.5842 18.7576 20.8359 19.9418C20.9562 20.5078 20.4923 21 19.9138 21H19C18.4477 21 18.0053 20.5511 17.9508 20.0015C17.7546 18.0229 16.9817 16.2142 15.8029 14.7451ZM13.7729 11.9957C13.6022 11.9879 13.535 11.7792 13.656 11.6585C14.3475 10.9687 14.9073 10.1568 15.3066 9.26275C15.765 8.23616 16.0013 7.12429 16 6C16.0019 4.77867 15.7229 3.57527 15.187 2.48208C15.1118 2.32865 15.2431 2.153 15.4071 2.20109C16.3697 2.48337 17.2287 3.04992 17.8685 3.8312C18.6004 4.72502 19.0002 5.84473 19 7C19.0003 7.71247 18.8482 8.41676 18.5541 9.06567C18.26 9.71459 17.8305 10.2931 17.2946 10.7625C16.7586 11.2319 16.1285 11.5814 15.4464 11.7874C14.9034 11.9514 14.3375 12.0215 13.7729 11.9957Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClients',
};
</script>
