<template>
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.253 8.0024C13.253 7.45645 13.6941 7.01387 14.2382 7.01387H17.0148C17.5589 7.01387 18 7.45645 18 8.0024C18 8.54835 17.5589 8.99093 17.0148 8.99093H14.2382C13.6941 8.99093 13.253 8.54835 13.253 8.0024Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6209 7.17338C16.165 7.17338 16.6061 7.61596 16.6061 8.16191V12.9976C16.6061 13.5436 16.165 13.9861 15.6209 13.9861C15.0768 13.9861 14.6357 13.5436 14.6357 12.9976V8.16191C14.6357 7.61596 15.0768 7.17338 15.6209 7.17338Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.985165 7.17338C1.52926 7.17338 1.97033 7.61596 1.97033 8.16191V12.9976C1.97033 13.5436 1.52926 13.9861 0.985165 13.9861C0.441073 13.9861 0 13.5436 0 12.9976V8.16191C0 7.61596 0.441073 7.17338 0.985165 7.17338Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.30305 8.99093C7.47245 8.99093 6.79911 9.66656 6.79911 10.5C6.79911 11.3334 7.47245 12.0091 8.30305 12.0091C9.13365 12.0091 9.80698 11.3334 9.80698 10.5C9.80698 9.66656 9.13365 8.99093 8.30305 8.99093ZM4.82878 10.5C4.82878 8.57466 6.38426 7.01387 8.30305 7.01387C10.2218 7.01387 11.7773 8.57466 11.7773 10.5C11.7773 12.4253 10.2218 13.9861 8.30305 13.9861C6.38426 13.9861 4.82878 12.4253 4.82878 10.5Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1266 6.63669C9.56721 5.07198 7.03895 5.07198 5.47957 6.63669C5.09484 7.02274 4.47107 7.02274 4.08634 6.63669C3.70161 6.25065 3.70161 5.62474 4.08634 5.2387C6.41518 2.9019 10.191 2.9019 12.5198 5.2387C12.9046 5.62474 12.9046 6.25065 12.5198 6.63669C12.1351 7.02274 11.5113 7.02274 11.1266 6.63669Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.08622 14.3633C4.47095 13.9773 5.09472 13.9773 5.47945 14.3633C7.03883 15.928 9.56709 15.928 11.1265 14.3633C11.5112 13.9773 12.135 13.9773 12.5197 14.3633C12.9044 14.7494 12.9044 15.3753 12.5197 15.7613C10.1909 18.0981 6.41506 18.0981 4.08622 15.7613C3.70149 15.3753 3.70149 14.7494 4.08622 14.3633Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8866 3.88211C10.3551 1.34205 6.25087 1.34205 3.71946 3.88211C3.33473 4.26815 2.71095 4.26815 2.32622 3.88211C1.94149 3.49606 1.94149 2.87016 2.32622 2.48411C5.6271 -0.828038 10.9789 -0.828038 14.2798 2.48411C14.6645 2.87016 14.6645 3.49606 14.2798 3.88211C13.8951 4.26815 13.2713 4.26815 12.8866 3.88211Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.32622 17.1179C2.71095 16.7318 3.33473 16.7318 3.71946 17.1179C6.25087 19.658 10.3551 19.658 12.8866 17.1179C13.2713 16.7318 13.8951 16.7318 14.2798 17.1179C14.6645 17.5039 14.6645 18.1298 14.2798 18.5159C10.9789 21.828 5.6271 21.828 2.32622 18.5159C1.94149 18.1298 1.94149 17.5039 2.32622 17.1179Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAssets',
};
</script>
