<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0H6C7.10457 0 8 0.895431 8 2V6C8 7.10457 7.10457 8 6 8H2C0.895431 8 0 7.10457 0 6V2ZM0 12C0 10.8954 0.895431 10 2 10H6C7.10457 10 8 10.8954 8 12V16C8 17.1046 7.10457 18 6 18H2C0.895431 18 0 17.1046 0 16V12ZM10 2C10 0.895431 10.8954 0 12 0H16C17.1046 0 18 0.895431 18 2V6C18 7.10457 17.1046 8 16 8H12C10.8954 8 10 7.10457 10 6V2ZM10 12C10 10.8954 10.8954 10 12 10H16C17.1046 10 18 10.8954 18 12V16C18 17.1046 17.1046 18 16 18H12C10.8954 18 10 17.1046 10 16V12Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDashboard',
};
</script>
