<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_3)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.87625C10.5992 0.87625 11.1924 0.994263 11.746 1.22355C12.2995 1.45284 12.8025 1.78891 13.2262 2.21258C13.6498 2.63624 13.9859 3.13921 14.2152 3.69276C14.4445 4.2463 14.5625 4.83959 14.5625 5.43875C14.5625 6.03791 14.4445 6.6312 14.2152 7.18474C13.9859 7.73829 13.6498 8.24126 13.2262 8.66492C12.8025 9.08859 12.2995 9.42466 11.746 9.65395C11.1924 9.88324 10.5992 10.0013 10 10.0013C8.78978 10.0013 7.62914 9.52049 6.77338 8.66474C5.91763 7.80899 5.43688 6.64834 5.43688 5.43813C5.43688 4.22791 5.91763 3.06726 6.77338 2.21151C7.62914 1.35576 8.78978 0.87625 10 0.87625ZM11.14 12.3162C15.6425 12.5962 19.1237 14.5163 19.1237 16.8425V19.125H10.9987V15.425L9.99875 14.3725L9.06625 15.425V19.125H0.875V16.8425C0.875 14.5163 4.35625 12.5963 8.85875 12.3175L10 13.9325L11.14 12.3162Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconEmployees',
};
</script>
