<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 17L17 22L12 17L12.7 16.2875L13.4 15.575L16 18.175V3H18V18.175L20.6 15.575L22 17ZM12 7L10.6 8.425L8 5.825V21H6L6 5.825L3.4 8.425L2 7L7 2L12 7Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconRequests',
};
</script>
