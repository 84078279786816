<template>
  <div class="layout-sidebar__item">
    <v-menu v-model="isOpen" content-class="layout-sidebar-language ml-2" top offset-x>
      <template #activator="{ on: onMenu }">
        <v-tooltip right content-class="layout-sidebar__tooltip language-tooltip">
          <template #activator="{ on: onTooltip }">
            <v-btn
              class="language-btn px-0"
              min-width="auto"
              width="32px"
              height="22px"
              depressed
              v-on="{ ...onMenu, ...onTooltip }"
              >{{ language.code }}</v-btn
            >
          </template>

          <span>Change language</span>
        </v-tooltip>
      </template>

      <v-list width="130" class="py-0">
        <v-list-item-group v-model="languageModel" mandatory>
          <v-list-item
            v-for="_language in languages"
            :key="_language.id"
            v-ripple="{ class: `primary--text` }"
            class="layout-sidebar-language__item"
            color="primary"
          >
            {{ _language.title }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LayoutSidebarLanguage',

  model: {
    prop: 'language',
    event: 'update:language',
  },

  props: {
    languages: { type: Array, default: () => [] },
    language: { type: Object, default: () => ({}) },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    languageModel: {
      get() {
        const foundIndex = this.languages.findIndex(language => language.id === this.language.id);

        return foundIndex;
      },

      set(index) {
        if (index !== undefined) {
          this.$emit('update:language', this.languages[index]);
        }
      },
    },

    menuArrowIcon() {
      return this.isOpen ? 'mdi-menu-up' : 'mdi-menu-down';
    },
  },
};
</script>

<style lang="scss">
.language-btn {
  border-radius: 8px !important;
  background: rgba(255, 255, 255, 0.1) !important;

  &:hover {
    background: #3359ff !important;
  }

  &:active,
  &:focus {
    background: #fff !important;

    &::before {
      opacity: 0 !important;
    }

    .v-btn__content {
      color: #002fff !important;
    }
  }

  .v-btn__content {
    color: #fff !important;
  }
}

.language-tooltip {
  margin-left: 13px;
}
</style>
