<template>
  <div class="notification-view">
    <v-container>
      <v-scale-transition group class="d-flex flex-column justify-center align-center">
        <v-alert
          v-for="notification in notificationPool"
          :key="notification.id"
          :type="notification.type"
          dismissible
          :width="notification.width"
        >
          <template v-if="notification.isLoad" #prepend>
            <v-progress-circular indeterminate size="24" width="2" class="me-5" />
          </template>
          {{ notification.message }}
        </v-alert>
      </v-scale-transition>
    </v-container>
  </div>
</template>

<script>
import notificationService from '@/services/notification';

export default {
  name: 'NotificationView',

  data() {
    return {
      notificationPool: [],
    };
  },

  mounted() {
    notificationService.addEventListener('created', this.pushNotification);
    notificationService.addEventListener('removed', this.removeNotification);
  },

  beforeDestroy() {
    notificationService.removeEventListener('created', this.pushNotification);
    notificationService.removeEventListener('removed', this.removeNotification);
  },

  methods: {
    pushNotification(item) {
      this.notificationPool = [...this.notificationPool, item];
    },

    removeNotification(item) {
      this.notificationPool = this.notificationPool.filter(notification => notification.id !== item.id);
    },
  },
};
</script>

<style lang="scss">
.notification-view {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000;
  width: 100%;
}
</style>
