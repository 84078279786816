<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.62096 18.9926C5.40608 18.8878 5.16269 18.8525 4.92932 18.9044L1.53414 19.659C0.819614 19.8178 0.18218 19.1804 0.340994 18.4659L1.09562 15.0707C1.14749 14.8373 1.11223 14.5939 1.00743 14.3791C0.343024 13.0167 -0.00227569 11.519 1.12865e-05 10C1.12865e-05 4.477 4.47701 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C8.48101 20.0023 6.98332 19.657 5.62096 18.9926Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChat',
};
</script>
