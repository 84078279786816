<template>
  <v-menu top offset-x nudge-right="-7">
    <template #activator="{ on: onMenu }">
      <v-tooltip right content-class="layout-sidebar__tooltip">
        <template #activator="{ on: onTooltip }">
          <v-btn class="layout-sidebar__item px-0" icon width="60px" v-on="{ ...onMenu, ...onTooltip }">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z"
              />
            </svg>
          </v-btn>
        </template>
        <span>{{ $t('profile.profile') }}</span>
      </v-tooltip>
    </template>

    <!-- @todo Реализовать переходы когда появится бэк -->
    <v-list width="250">
      <v-list-item-group>
        <v-list-item :to="$options.profileLink" class="primary--text">
          {{ $t('profile.profile') }}
        </v-list-item>
        <v-list-item @click="logout">
          {{ $t('profile.exit') }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// node_modules
import { mapActions } from 'vuex';

// Constants
import { LOGIN, PROFILE } from '@/constants/routes';
import { LOG_OUT as analyticsActionLogout } from '@/constants/analyticsActions';
import { LOGOUT } from '@/store/modules/user/actions/types';

// Services
import analyticsService from '@/services/analytics';

export default {
  name: 'LayoutSidebarProfile',
  profileLink: { name: PROFILE },

  methods: {
    ...mapActions('user', {
      userLogout: LOGOUT,
    }),

    logout() {
      analyticsService.track(analyticsActionLogout);
      this.userLogout();
      this.$router.push({ name: LOGIN });
    },

    checkActive() {
      return this.$route.name === PROFILE;
    },
  },
};
</script>
